<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card
        class="md-theme-default md-card-wizard active"
        style="min-height: unset;"
      >
        <ValidationObserver ref="form">
          <form @submit.prevent="validate">
            <md-card-header class="md-card-header text-center">
              <h3 class="title">
                {{ title }}
              </h3>
              <h5 class="category">
                {{ subtitle }}
              </h5>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item">
                  <TeachersSelect
                    v-model="form.teacher_id"
                    :disabled="!editable"
                    rules="required"
                  />
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item">
                  <form-text
                    v-model="form.classroom_code"
                    name="code"
                    rules="required"
                    icon="code"
                    label="Code"
                    :disabled="!editable"
                  />
                </div>
                <div class="md-layout-item">
                  <ClassroomNamesSelect
                    v-model="form.classroom_name_id"
                    :disabled="!editable"
                  />
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item">
                  <form-number
                    v-model="form.limit"
                    name="limit"
                    rules="required"
                    icon="people"
                    label="Max. Students"
                    :disabled="!editable"
                  />
                </div>
                <div class="md-layout-item">
                  <PeriodsSelect
                    v-model="form.class_schedule_id"
                    :disabled="!editable"
                    rules="required"
                  />
                </div>
                <div class="md-layout-item">
                  <LevelsSelect
                    v-model="form.level_id"
                    :disabled="!editable"
                    rules="required"
                  />
                </div>
                <div class="md-layout-item">
                  <CoursesSelect
                    v-model="form.course_id"
                    :disabled="!editable"
                    rules="required"
                  />
                </div>
              </div>
            </md-card-content>
            <md-card-actions md-alignment="space-between">
              <md-button
                class="btn-previous"
                @click.native="$router.push({name:'Classrooms'})"
              >
                Cancel
              </md-button>
              <md-button
                v-if="!editable"
                class="md-success"
                @click="editable = true"
              >
                Edit Classroom
              </md-button>
              <md-button
                v-if="editable && $route.name === 'ClassroomDetails'"
                class="md-danger"
                @click="editable = false"
              >
                Cancel Edit
              </md-button>
              <md-button
                v-if="editable"
                class="md-success"
                @click="saveClassroom"
              >
                Save
              </md-button>
            </md-card-actions>
          </form>
        </ValidationObserver>
      </md-card>
    </div>

    <template v-if="$route.name === 'ClassroomDetails'">
      <div class="md-layout-item">
        <md-card
          class="md-theme-default md-card-wizard active"
          style="min-height: unset;"
        >
          <md-card-header class="md-card-header text-center">
            <h3 class="title">
              Enrolments
            </h3>
          </md-card-header>
          <md-card-content>
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              :search="false"
              @changed="getClassroomStudents"
              @toProgramme="toProgramme"
              @toStudent="toStudent"
            />
          </md-card-content>
        </md-card>
      </div>

      <div class="md-layout-item">
        <md-card
          class="md-theme-default md-card-wizard active"
          style="min-height: unset;"
        >
          <md-card-header class="md-card-header text-center">
            <h3 class="title">
              Pre Enrolled Starting this week
            </h3>
          </md-card-header>
          <md-card-content>
            <vue-table
              v-if="vTable2.headers"
              ref="vtable2"
              :values="vTable2.values"
              :headers="vTable2.headers"
              :actions="vTable2.actions"
              :options="{
                tableClass:'table'
              }"
              :search="false"
              @changed="getPreenrolled"
              @toProgramme="toProgramme"
              @toStudent="toStudent"
            />
          </md-card-content>
        </md-card>
      </div>

      <div class="md-layout-item">
        <md-card
          class="md-theme-default md-card-wizard active"
          style="min-height: unset;"
        >
          <md-card-header class="md-card-header text-center">
            <h3 class="title">
              Holidays
            </h3>
          </md-card-header>
          <md-card-content>
            <vue-table
              v-if="vTable3.headers"
              ref="vtable3"
              :values="vTable3.values"
              :headers="vTable3.headers"
              :actions="vTable3.actions"
              :options="{
                tableClass:'table'
              }"
              :search="false"
              @changed="getHolidays"
              @toProgramme="toProgramme"
              @toStudent="toStudent"
            />
          </md-card-content>
        </md-card>
      </div>
    </template>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import {
  FormText,
  FormNumber,
} from '@/components/Inputs';
import {
  TeachersSelect,
  PeriodsSelect,
  LevelsSelect,
  CoursesSelect,
  ClassroomNamesSelect,
} from '@/components/Inputs/selects';

extend('required', required);
extend('email', email);

export default {
  components: {
    FormNumber,
    FormText,
    VueTable,
    TeachersSelect,
    PeriodsSelect,
    LevelsSelect,
    CoursesSelect,
    ClassroomNamesSelect,
  },
  data() {
    return {
      form: {
        classroom_code: null,
        classroom_name_id: null,
        teacher_id: null,
        course_id: null,
        level_id: 1,
        class_schedule_id: 2,
        limit: 15,
      },
      title: 'Classroom Form',
      subtitle: 'Complete the form.',
      editable: true,
      vTable: {
        headers: [
          {
            title: 'programme.student.student_number',
            mask: 'Student Number',
            sortable: true,
          },
          {
            title: 'programme.student.first_name',
            mask: 'Name',
            sortable: true,
          },
          {
            title: 'programme.student.last_name',
            mask: 'Surname',
            sortable: true,
          },
          {
            title: 'programme.student.email',
            mask: 'Email',
            sortable: true,
          },
          {
            title: 'programme.start_date',
            mask: 'start date',
            dateFormat: true,
            sortable: true,
          },

          {
            title: 'programme.finish_date',
            mask: 'finish date',
            dateFormat: true,
            sortable: true,
          },

          {
            title: 'programme.tuition_finish_date',
            mask: 'tuition finish date',
            dateFormat: true,
            sortable: true,
          },
          {
            title: 'programme.attendance.attendance_rate',
            after: '%',
            mask: 'attendance rate',
            sortable: true,
          },
          {
            title: 'programme.status',
            mask: 'status',
            sortable: true,
          },

        ],
        actions: [
          {
            buttonClass: 'md-info',
            tooltip: 'Go to Programme',
            callback: 'toProgramme',
            icon: 'near_me',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Go to Student',
            callback: 'toStudent',
            icon: 'person_pin',
          },
        ],
        values: {},
      },
      vTable2: {
        headers: [
          {
            title: 'programme.student.student_number',
            mask: 'Student Number',
            sortable: true,
          },
          {
            title: 'programme.student.first_name',
            mask: 'Name',
            sortable: true,
          },
          {
            title: 'programme.student.last_name',
            mask: 'Surname',
            sortable: true,
          },
          {
            title: 'programme.student.email',
            mask: 'Email',
            sortable: true,
          },
          {
            title: 'programme.start_date',
            mask: 'start date',
            dateFormat: true,
            sortable: true,
          },
          {
            title: 'programme.finish_date',
            mask: 'finish date',
            dateFormat: true,
            sortable: true,
          },
          {
            title: 'programme.tuition_finish_date',
            mask: 'tuition finish date',
            dateFormat: true,
            sortable: true,
          },
          {
            title: 'programme.attendance.attendance_rate',
            after: '%',
            mask: 'attendance rate',
            sortable: true,
          },
          {
            title: 'programme.status',
            mask: 'status',
            sortable: true,
          },
          {
            title: 'start_date',
            mask: 'enrolment start date',
            dateFormat: true,
            sortable: true,
          },

        ],
        actions: [
          {
            buttonClass: 'md-info',
            tooltip: 'Go to Programme',
            callback: 'toProgramme',
            icon: 'near_me',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Go to Student',
            callback: 'toStudent',
            icon: 'person_pin',
          },
        ],
        values: {},
      },
      vTable3: {
        headers: [
          {
            title: 'programme.student.student_number',
            mask: 'Student Number',
            sortable: true,
          },
          {
            title: 'programme.student.first_name',
            mask: 'Name',
            sortable: true,
          },
          {
            title: 'programme.student.last_name',
            mask: 'Surname',
            sortable: true,
          },
          {
            title: 'programme.student.email',
            mask: 'Email',
            sortable: true,
          },
          {
            title: 'programme.start_date',
            mask: 'start date',
            dateFormat: true,
            sortable: true,
          },
          {
            title: 'programme.finish_date',
            mask: 'finish date',
            dateFormat: true,
            sortable: true,
          },
          {
            title: 'programme.tuition_finish_date',
            mask: 'tuition finish date',
            dateFormat: true,
            sortable: true,
          },
          {
            title: 'programme.attendance.attendance_rate',
            after: '%',
            mask: 'attendance rate',
            sortable: true,
          },
          {
            title: 'programme.status',
            mask: 'status',
            sortable: true,
          },
          {
            title: 'programme.holiday.finish_date',
            mask: 'holiday finish date',
            dateFormat: true,
            sortable: true,
          },

        ],
        actions: [
          {
            buttonClass: 'md-info',
            tooltip: 'Go to Programme',
            callback: 'toProgramme',
            icon: 'near_me',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Go to Student',
            callback: 'toStudent',
            icon: 'person_pin',
          },
        ],
        values: {},
      },
    };
  },
  mounted() {
    if (this.$route.name === 'ClassroomDetails') {
      this.getClassroom();
      this.$refs.vtable.init();
      this.$refs.vtable2.init();
      this.$refs.vtable3.init();
      this.title = 'Classroom Details';
      this.subtitle = null;
      this.editable = false;
    }
  },
  methods: {
    saveClassroom() {
      if (this.$route.name === 'ClassroomDetails') {
        this.request(this.$API.CLASSROOMS + this.$route.params.id, 'put', this.form, () => {
          this.fireSuccess('Classroom updated succesfully');
          this.editable = false;
        });
      } else {
        this.request(this.$API.CLASSROOMS, 'post', this.form, () => {
          this.fireSuccess('Classroom created succesfully');
          this.$router.push({ name: 'Classrooms' });
        });
      }
    },
    getClassroom() {
      this.request(this.$API.CLASSROOMS + this.$route.params.id, 'get', null, ({ data }) => {
        this.form = data;
      });
    },
    getClassroomStudents(params) {
      this.request(`${this.$API.CLASSROOMS + this.$route.params.id}/enrolments`,
        'get',
        params,
        ({ data }) => {
          this.vTable.values = data;
        });
    },
    getPreenrolled(params) {
      this.request(`${this.$API.CLASSROOMS + this.$route.params.id}/enrolments`,
        'get',
        {
          ...params,
          pre_enrolled: true,
        },
        ({ data }) => {
          this.vTable2.values = data;
        });
    },
    getHolidays(params) {
      this.request(`${this.$API.CLASSROOMS + this.$route.params.id}/enrolments`,
        'get',
        {
          ...params,
          holidays: true,
        },
        ({ data }) => {
          this.vTable3.values = data;
          this.vTable3.values.data = this.vTable3.values.data.map((x) => {
            // eslint-disable-next-line prefer-destructuring
            x.programme.holiday = x.programme.holidays[0];
            return x;
          });
        });
    },
    toProgramme(item, isMiddleClick) {
      this.openLink({ name: 'ProgrammeDetails', params: { id: item.programme_id } }, isMiddleClick);
    },
    toStudent(item, isMiddleClick) {
      this.openLink({ name: 'StudentResume', params: { id: item.programme.student_id } }, isMiddleClick);
    },
  },
};
</script>

<style>

</style>
